import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

interface FormState {
  name: string;
  email: string;
  subject:string;
  message: string;
}

const ContactUs: React.FC = () => {
    const form: any = useRef();
 
    const sendEmail = (e:any) => {
        e.preventDefault();
        
        if(!(SERVICE_ID == undefined) && !(TEMPLATE_ID == undefined) && !(PUBLIC_KEY == undefined)){
        
        emailjs
          .sendForm("service_bvvul8h", "template_4g1rz2n", form.current, "zDH3007mKG62YNGvj")
          .then(
            () => {
              console.log('SUCCESS!');
              console.log(form.current)
            },
            (error) => {
              console.log('FAILED Again', error);
            },
          );
        }
        else{
            console.log("somethis is undefined")
        }
      };

  return (
    <form ref={form} onSubmit={sendEmail} className='text-hero flex flex-col w-full md:w-1/3'>
      <label className='text-backwhite mt-4 py-1'>Full Name</label>
      <input type="text" name="user_name" className='py-2 px-1 rounded-md'/>
      <label className='text-backwhite mt-4 py-1'>Email</label>
      <input type="email" name="user_email" className='py-2 px-1 rounded-md'/>
      <label className='text-backwhite mt-4 py-1'>Subject</label>
      <input type="text" name="subject" className='py-2 px-1 rounded-md'/>
      <label className='text-backwhite mt-4 py-1'>Message</label>
      <textarea name="message" className='py-2 px-1 h-40 rounded-md'/>
      <input type="submit" value="Submit" className=' self-center md:self-start text-backwhite text-[1rem] my-14 md:my-6 border px-3 py-2 w-40 rounded hover:bg-white hover:text-gray-900 transition-all cursor-none' />
    </form>
  );
};

export default ContactUs;
