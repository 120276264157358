
import  { useRef, useState, useEffect, CSSProperties, useContext } from 'react'

import {
    motion
  } from "framer-motion";

import { Works } from '../../constants/models';
import { WorkContent } from "../landing-page/work";
import NavbarComponent from '../../components/navbar';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CaretDown } from './../../assets/caret-down-svgrepo-com.svg';


export default function ProjectPage() {

    const getDefaultState = () => {
        return window.innerWidth > 768 ? 1 : 0;
      };

    const [showWork, setShowWork] = useState(0)
    const [workNumber, setWorkNumber] = useState(1)
    const projectRef = useRef<HTMLDivElement>(null)
    const [blockScroll, setBlockScroll] = useState(0)
    const[mouseVariant, setMouseVariant] = useState("default")
    const navigate = useNavigate()
    const [drop, setDrop] = useState(getDefaultState)
    const [tag, setTag] = useState('Product Design')
    const [FilteredWorkContent, setFilteredWorkContent] = useState(WorkContent)

    const[mousePosition, setMousePosition] = useState({
        x : 0,
        y : 0,
    })
    

    const variants = {
        default : {
            x : mousePosition.x -16,
            y : mousePosition.y -16,
            
        },

        text : {
            x : mousePosition.x - 16 ,
            y : mousePosition.y - 16,
            scale: 8,
        },

        floating : {
            x : mousePosition.x - 64,
            y : mousePosition.y - 32,
        }
    }


    let WIDTH = window.outerWidth
    let SCALE = (window.innerWidth > 640)  ? 1 : 0



    async function animateWork(content: Works){
        setShowWork(-1*WIDTH); 
        setWorkNumber(content.key||0);
        if (null !== projectRef.current) {
            projectRef.current.scrollIntoView({ behavior: 'smooth' });
        
        return true

    }}

    function incrementWork(){
        const num = workNumber
        if (num == WorkContent.length)
            {setWorkNumber(1)}
        else
            {setWorkNumber(num+1)}

        var projectImage = document.getElementById('project-image');
        if(projectImage){
            const scrollToOptions = { top: 0, left: 0, behavior: 'instant' }
            projectImage.scrollTo(scrollToOptions as unknown as ScrollToOptions);
        }
        
    }

    function decrementWork(){
        const num = workNumber
        if (num == 1)
            {setWorkNumber(WorkContent.length)}
        else
            {setWorkNumber(num-1)}
        var projectImage = document.getElementById('project-image');
        if(projectImage){
            const scrollToOptions = { top: 0, left: 0, behavior: 'instant' }
            projectImage.scrollTo(scrollToOptions as unknown as ScrollToOptions);
        }

    }

    function handleContactClick() {

        window.scrollTo({
            top: document.body.scrollHeight,
            left:-100,
            behavior: 'smooth',
        });
    }

    function handleProjectClick() {
        navigate('projects')
    }

    function handleAboutClick() {
        
    }

    function filterWork(work: Works){
        
        
        if (tag === 'All Projects'){
            return (<WorkList content={work}></WorkList>)
        }
        else if (work.tags){
            if (work.tags.indexOf(tag) > -1){
            return (<WorkList content={work}></WorkList>)
            }
        }
        

    }

    function WorkList({content} : {content: Works}){
        
        return(<div className='group py-3 px-2 relative text-backwhite' onClick={async ()=>{ let done = await animateWork(content); if(done){ setBlockScroll(1)} }}>
            <div className ='w-full group-hover:border-t absolute -top-1 border-backwhite'></div>
            <div className='flex flex-row md:justify-between cursor-none' >
                <h2 className='text-sm md:text-4xl font-medium uppercase group-hover:ease-in group-hover:duration-100'>{content.company}</h2>
                {/* <h2 className='text-sm md:text-4xl font-medium uppercase font-design italic absolute top-3 opacity-0 group-hover:opacity-100 group-hover:ease-in group-hover:duration-100'>{content.company}</h2> */}
                <div className='hidden md:block opacity-0 uppercase md:group-hover:opacity-100 md:group-hover:ease-in md:group-hover:duration-100'>{content.period}</div>
            </div>
            <div className ='w-full group-hover:border-b absolute -bottom-1 border-backwhite '></div>
        </div>)}

    function SidePanel({content} : {content: Works}){
        return( <div className=" h-32 md:h-full bg-gradient-to-t from-black from-30% md:bg-none text-gray-200 md:text-backwhite">
                    <div className=' py-5 md:h-full flex flex-col justify-end md:justify-between  w-full ml-6'>
                        <div className='hidden md:block'>
                            <h2 className='text-5xl font-medium uppercase py-1'>{(content.key == 7)? "Storm Communic- ations" : content.company}</h2>
                            <div className='pt-1 text-[0.9rem]'>{content.work1}</div>
                            <div className='pt-1 text-[0.9rem]'>{content.work2}</div>
                            <div className='pt-1 text-[0.9rem]'>{(content.work3)? content.work3 : ""}</div>
                        </div>

                        <div className="ml-4 md:ml-0">
                            {(content.website)?<div className="relative group">
                                <div className='pt-1 md:p-2 hover:underline' onClick={(e)=>{if(content.website){window.location.href = content.website};e.preventDefault()}}>VISIT WEBSITE</div>
                                {/* <div className='pt-1 md:p-2 font-design italic absolute top-0 opacity-0 group-hover:opacity-100 group-hover:ease-in group-hover:duration-100 underline' onClick={(e)=>{if(content.website){window.location.href = content.website};e.preventDefault()}}>VISIT WEBSITE</div> */}
                            </div>: ""}
                            {(content.presentation)?<div className="relative group">
                                <div className='pt-1 md:p-2 hover:underline' onClick={(e)=>{if(content.presentation){window.location.href = content.presentation};e.preventDefault()}}>VISIT WEBSITE</div>
                                {/* <div className='pt-1 md:p-2 font-design italic absolute top-0 opacity-0 group-hover:opacity-100 group-hover:ease-in group-hover:duration-100 underline' onClick={(e)=>{if(content.presentation){window.location.href = content.presentation};e.preventDefault()}}>VISIT WEBSITE</div> */}
                            </div>: ""}
                            <div className="relative group" onClick={()=>{incrementWork()}}>
                                <div className='pt-1 md:p-2 hover:underline' >NEXT PROJECT</div>
                                {/* <div className='pt-1 md:p-2 font-design italic absolute top-0 opacity-0 group-hover:opacity-100 group-hover:ease-in group-hover:duration-100 underline' onClick={()=>{incrementWork()}}>NEXT PROJECT</div> */}
                            </div>
                            <div className="relative group" onClick={()=>{decrementWork()}}>
                                <div className='pt-1 md:p-2 hover:underline' >PREVIOUS PROJECT</div>
                                {/* <div className='pt-1 md:p-2 font-design italic absolute top-0 opacity-0 group-hover:opacity-100 group-hover:ease-in group-hover:duration-100 underline' onClick={()=>{decrementWork()}}>PREVIOUS PROJECT</div> */}
                            </div>
                            <div className="relative group " onClick={()=>{setShowWork(0)}}>
                                <div className='pt-1 md:p-2  hover:underline' >CLOSE</div>
                                {/* <div className='pt-1 md:p-2 font-design italic absolute top-0 opacity-0 group-hover:opacity-100 group-hover:ease-in group-hover:duration-100 underline' onClick={()=>{setShowWork(0); setBlockScroll(0)}}>CLOSE</div> */}
                            </div>

                            </div>
                        </div>
            </div>)
        }

        useEffect(() => {
            const mouseMove = (e: MouseEvent) => {
                setMousePosition({
                    x: e.clientX,
                    y: e.clientY,
                })
            }
            window.addEventListener('mousemove', mouseMove)
    
            return () => {
                window.removeEventListener('mousemove', mouseMove)
            }
    
        }, []);



  return (
    <>
        <div className="bg-gradient-to-b from-black from-30% to-[#b3b3b3] bg-cover bg-center bg-fixed min-h-screen">
            <motion.div variants={variants} className='hidden lg:block cursor fixed top-0 left-0 z-50' animate={mouseVariant} transition={{ type: "spring", duration: 0.0 }} >
            </motion.div>

            
            {(showWork==0) && <NavbarComponent isVisible={true}/>}

            <div className=" w-full relative text-[0.8rem] container mx-auto min-w-full md:min-w-0 md:max-w-screen-xl scrollbar">
                            <div ref={projectRef} className='absolute -top-20'></div>
                            <motion.div animate={{x: showWork}} transition={{type: 'spring', bounce:0.1}} className=" text-backwhite h-full min-h-[90vh] flex-col flex mx-8 mr-12 justify-between ">
                                <div className=''>
                                <div className='flex'><div className='md:my-6 py-1 -translate-x-2 md:translate-x-0 px-3 font-bold uppercase flex-none border border-backwhite rounded-xl' onClick={() => {
                                        let temp = (drop + 1) %2
                                        setDrop(temp)
                                    }}>{tag}<CaretDown className='inline w-6 h-5'/></div></div>  

                                {(drop == 0)?"": <div className='flex flex-col text-start md:text-center md:flex-row md:-translate-y-[3.2rem] md:translate-x-48 md:h-0 z-30 text-[0.9rem] font-light'> 
                                        <div className='px-4 mx-1 border border-transparent hover:underline' onClick={() => { setDrop(0); setTag('All Projects')}}> All Projects</div>
                                        <div className='px-4 mx-1 border border-transparent hover:underline' onClick={() => {setDrop(0); setTag('Product Design')}}> Product Design </div>
                                        <div className='px-4 mx-1 border border-transparent hover:underline'  onClick={() => {setDrop(0); setTag('Content Strategy')}}> Content Strategy </div>
                                        <div className='px-4 mx-1 border border-transparent hover:underline'  onClick={() => {setDrop(0);setTag('Visual Design')}}> Visual Design </div>
                                    </div>}
                                <div className="">
                                { WorkContent.map((work,index)=>(
                                    filterWork(work)
                                ))}
                                </div>
                                </div>
                                
                                <div  className='pb-10 pt-5 w-2/3 lg:w-1/2 px-2 '>
                                If you would like to see more of a project or a specific project, please contact me and I'll be happy to set up a call.
                                </div>  
                            </motion.div>

                            {(showWork)?<motion.div animate={{x: -SCALE*10}} transition={{type: 'spring', bounce:0.}} className="w-screen h-screen fixed left-0  top-0 flex flex-col md:flex-row justify-between md:justify-normal"> 
                                <div className='w-full md:w-3/4 h-[80%] md:h-full'>
                                    <div id="project-image" className='overflow-y-auto h-full w-full scrollbar'>
                                        {
                                            (WorkContent[workNumber-1].file_desk || WorkContent[workNumber-1].file_mob)? 
                                        // <embed
                                        // src={WorkContent[workNumber-1].file + "#toolbar=0"}
                                        // type="application/pdf"
                                        // width={window.innerWidth*(0.78*SCALE + (1-SCALE))}
                                        // className="h-full"

                                        // /> 
                                        (SCALE)?<img src={WorkContent[workNumber-1].file_desk}></img>: <img src={WorkContent[workNumber-1].file_mob}></img>
                                            : ""}
                                        
                                    </div>
                                </div>
                                <div className="w-full h-1/3 md:h-full flex flex-col justify-end md:justify-normal md:w-1/4 z-10">
                                    <SidePanel content={WorkContent[workNumber-1]}></SidePanel>
                                    <div className='h-10 bg-black md:hidden'></div>
                                </div>
                                
                            </motion.div>:""}
                            
                        </div> 
            </div>
    </>
    
  )
}
