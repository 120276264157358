import imageConnected from '../../assets/calendars-connected.png'
import SKED from './../../assets/SKED.png'
import Branch from './../../assets/Branch.png'
import Nescafe from './../../assets/Nescafe.png'
import Maggi from './../../assets/Maggi.png'
import FOB from './../../assets/Flower-of-Bones.png'
import Outlook from './../../assets/Outlook-Traveller.png'
import StormComm from './../../assets/Storm-Communications.png'
import VoltEur from './../../assets/Volt-Europa.png'
import StormBrand from './../../assets/Storm-brand.png'


export const WorkContent = [
    {   key:1,
        company: "Branch Metrics" ,
        work1:"Adtech, Mobile, Product",
        work2:"APAC, EMEA, NA",
        period: "Adtech, Mobile, Product",
        file_desk: Branch,
        file_mob: Branch,
        website:"",
        presentation:"",
        tags: ['Product Design',]
    },

    {   key:2,
        company: "SKED" ,
        work1:"Mobile, Productivity, UI/UX, Product",
        work2:"Europe",
        period: "Mobile, Productivity, UI/UX, Product",
        file_desk: SKED,
        file_mob: SKED,
        website:"",
        presentation:"",
        tags: ['Visual Design', 'Product Design']
        

    },
    
    {   key:3,
        company: "Nescafe" ,
        work1:"UX, Digital, B2C",
        work2:"Global",
        period: "UX, Digital, B2C",
        file_desk: Nescafe,
        file_mob: Nescafe,
        presentation:"",
        website:"",
        tags: ['Content Strategy', 'Product Design']
        
    },
    {   key:4,
        company: "Maggi" ,
        work1:"UX/UI, Digital, B2C",
        work2:"Global",
        period: "UX/UI, Digital, B2C",
        file_desk: Maggi,
        file_mob: Maggi,
        presentation:"",
        website:"",
        tags: ['Visual Design', 'Product Design']
    },
    {   key:5,
        company: "Flower of Bones" ,
        work1:"Author, Not for Profit, Illustration",
        work2:"India",
        period: "Author, Not for Profit, Illustration",
        file_desk:FOB,
        file_mob:FOB,
        website:"",
        presentation:"",
        tags: ['Visual Design', 'Content Strategy']

    },
    {   key:6,
        company: "Outlook Publishing" ,
        work1:"Graphic, Visual, Print, Journalism",
        work2:"India",
        period: "Graphic, Visual, Print, Journalism",
        file_desk: Outlook,
        file_mob: Outlook,
        website:"",
        presentation:"",
        tags: ['Visual Design']

    },
    {   key:7,
        company: "Storm Communications " ,
        work1:"UX/UI, Events, B2B",
        work2:"India, Nepal",
        period: "X/UI, Events, B2B",
        file_desk: StormComm,
        file_mob: StormComm,
        website:"",
        presentation:"",
        tags: ['Product Design']

    },
    // {   key:8,
    //     company: "User Experior " ,
    //     work1:"SaaS, Branding, Marketing, UX/UI",
    //     work2:"India",
    //     period: "SaaS, Branding, Marketing, UX/UI",
    //     file:"",
    //     website:"",
    //     presentation:"",
    //     tags: ['Product Design']

    // },
    {   key:9,
        company: "Volt Europa" ,
        work1:"Pitch, Infrastructure, Mobile, Product",
        work2:"Europe",
        period: "Pitch, Infrastructure, Mobile, Product",
        file_desk: VoltEur,
        file_mob: VoltEur,
        website:"",
        presentation:"",
        tags: ['Content Strategy']

    },

    // {   key:10,
    //     company: "Sangeetcar" ,
    //     work1:"Branding, Not for Profit, UI, Productiont",
    //     work2:"India",
    //     period: "Branding, Not for Profit, UI, Productiont",
    //     file:"",
    //     website:"",
    //     presentation:"",
    //     tags: ['Visual Design', 'Content Strategy']


    // },
    {   key:11,
        company: "Storm, Brand Identity" ,
        work1:"Branding, Social Media, Graphic",
        work2:"India, Nepal",
        period: "Branding, Social Media, Graphic",
        file_desk: StormBrand,
        file_mob: StormBrand,
        website:"",
        presentation:"",
        tags: ['Visual Design',]


    },

    
]